"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    USER_DATA_LOADED: 'user/USER_DATA_LOADED',
    USER_DATA_CLEARED: 'user/USER_DATA_CLEARED',
    GET_USER_RIGHTS_FULFILLED: 'user/GET_USER_RIGHTS_FULFILLED',
    GET_USER_RIGHTS_REJECTED: 'user/GET_USER_RIGHTS_REJECTED',
    GET_USER_STATE_FULFILLED: 'user/GET_USER_STATE_FULFILLED',
    GET_CONSENT_IN_PROGRESS: 'user/GET_CONSENT_IN_PROGRESS',
    GET_CONSENT_FULFILLED: 'user/GET_CONSENT_FULFILLED',
    GET_CONSENT_REJECTED: 'user/GETCONSENT__REJECTED',
    COOKIE_CONSENTS_FULFILLED: 'user/COOKIE_CONSENTS_FULFILLED'
};
