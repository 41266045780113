"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const script_1 = require("@src/utils/helpers/script");
const hasScriptStartedLoading = () => Boolean(document.getElementById('svp-player-sdk'));
let isLibInitialized = false;
const useSvpPlayer = (id, node, vendor, locale, pulseProvider, consents) => {
    const playerRef = (0, react_1.useRef)();
    const [isPlayerReady, setIsPlayerReady] = (0, react_1.useState)(false);
    const pulseConfig = pulseProvider
        ? { pulse: { provider: pulseProvider, sdkConfig: { consents } } }
        : { pulse: { sdkConfig: { consents } } };
    const playerOptions = Object.assign({ sharing: false, settings: { na: true }, recommended: { next: false } }, pulseConfig);
    (0, react_1.useEffect)(() => {
        const onSvpPlayerReady = () => {
            isLibInitialized = true;
            playerRef.current = new window.SVP.Player(Object.assign({ id,
                vendor,
                node,
                locale }, playerOptions));
            setIsPlayerReady(true);
        };
        if (isLibInitialized) {
            onSvpPlayerReady();
        }
        else {
            window.addEventListener('onSvpPlayerReady', onSvpPlayerReady);
        }
        if (!hasScriptStartedLoading()) {
            (0, script_1.loadScript)('https://cdn.stream.schibsted.media/player/3.69.0/player.next.min.js', 'svp-player-sdk');
        }
        return () => window.removeEventListener('onSvpPlayerReady', onSvpPlayerReady);
    }, [node]);
    return { isPlayerReady };
};
exports.default = useSvpPlayer;
