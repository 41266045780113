import types from './types';
import initialState from './initialState';

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.USER_DATA_LOADED: {
      return {
        ...state,
        displayName: action.response.displayName,
        firstName: action.response.givenName,
        lastName: action.response.familyName,
        userId: action.response.userId,
        sig: action.response.sig
      };
    }
    case types.USER_DATA_CLEARED: {
      return initialState;
    }
    case types.GET_USER_RIGHTS_FULFILLED: {
      return {
        ...state,
        isAdmin: action.isAdmin,
        isMenuAdmin: action.isMenuAdmin
      };
    }
    case types.GET_USER_RIGHTS_REJECTED: {
      return {
        ...state,
        isAdmin: false,
        isMenuAdmin: false
      };
    }
    case types.GET_USER_STATE_FULFILLED: {
      return {
        ...state,
        displayName: action.response.userData.displayName,
        firstName: action.response.userData.givenName,
        lastName: action.response.userData.familyName,
        userId: action.response.userData.userId,
        vkUserId: action.response.vkUserId,
        sig: action.response.userData.sig,
        isLoggedIn: action.response.isLoggedIn,
        hasSubscription: action.response.hasSubscription
      };
    }

    case types.GET_CONSENT_IN_PROGRESS: {
      return { ...state, loading: true };
    }
    case types.GET_CONSENT_FULFILLED: {
      return {
        ...state,
        loading: false,
        productImprovementConsentStatus: action.response.consentStatus,
        productImprovementConsentShouldAsk: action.response.shouldAsk
      };
    }
    case types.GET_CONSENT_REJECTED: {
      return { ...state, loading: false };
    }
    case types.COOKIE_CONSENTS_FULFILLED: {
      return {
        ...state,
        cookieConsents: action.response
      };
    }
    default:
      return state;
  }
};

export default userReducer;
